<template>
  <div class="page">
    <el-row :gutter="20">
      <el-col :span="18">
        <el-table :data="goodsList" border>
          <el-table-column label="货号/SKU" prop="skuid"></el-table-column>
          <el-table-column label="商品信息">
            <template slot-scope="scope">
              <div class="goodsInfo" >
                <el-image :src="scope.row.imgUrl || scope.row.gallery"/>
                <span>{{scope.row.name}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="销售价格">
            <template slot-scope="scope">
              <el-input type="number" :min="0" v-model="scope.row.price"/>
            </template>
          </el-table-column>
          <el-table-column label="库存">
            <template slot-scope="scope">
              <el-input type="number" :min="0" v-model="scope.row.stock"/>
            </template>
          </el-table-column>
          <el-table-column label="品牌">
            <template slot-scope="scope">
              <el-select v-model="scope.row.brand_id" placeholder="请选择品牌">
                <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="分类">
            <template slot-scope="scope">
              <el-select v-model="scope.row.category_id" placeholder="请选择品牌">
                <el-option v-for="item in goodsCategoryList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="goodsList.splice(scope.$index,1)">删除</el-button>
            </template>
          </el-table-column>
          <el-table-column label="执行结果">
            <template slot-scope="scope">
              <span v-if="resultList.length > 0">{{resultList[scope.$index].msg}}</span>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 10px;display: flex;align-items: center">
          <el-button type="primary" @click="setOnce">执行</el-button>
        </div>

      </el-col>
    </el-row>
  </div>
</template>

<script>
import {apiGetGoodsOptions,apiModfiyGoodsBaseOnce} from '@/request/api'
export default {
  name: "setOnce",
  data(){
    return {
      goodsList:[],
      brandList:[],
      goodsCategoryList:[],
      resultList:[],
    }
  },

  created() {
    this.goodsList = this.$route.params.goodsList;
    this.getOption();
  },
  methods:{
    setOnce(){
      var list = []
      this.goodsList.forEach(item=>{
        var obj ={
          category_id: item.category_id,
          brand_id: item.brand_id,
          skuId: item.skuid,
          price: item.price,
          stock: item.stock
        }
        list.push(obj)
      })
      apiModfiyGoodsBaseOnce({sku:list}).then(res=>{
        if(res.code == 200){
          this.resultList = res.data;
        }
      })
    },
    getOption(){
      apiGetGoodsOptions().then(res => {
        if(res.code == 200){
          this.brandList = res.data.brandCate;
          this.goodsCategoryList = res.data.goodsCate;
        }
      })
    }
  },
}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
  /*margin: 20px;*/
}
</style>